<template>
  <div>
    <Toast position="top-right" />
    <div
      class="d-flex flex-column justify-content-between align-items-between h-100"
    >
      <b-modal
        id="modal-detalhes-campanha"
        body-class="p-0"
        size="lg"
        hide-footer
        hide-header
      >
        <modal-detalhes-campanha
          @botao-voltar="$bvModal.hide('modal-detalhes-campanha')"
          :campanha="itemSelecionado"
          @editar-campanha="editarCampanha"
        />
      </b-modal>
      <modal
        name="modal-detalhes-campanha"
        scrollable
        width="69%"
        height="auto"
      >
        <modal-detalhes-campanha
          @botao-voltar="$modal.hide('modal-detalhes-campanha')"
          :campanha="itemSelecionado"
          @editar-campanha="editarCampanha"
        />
      </modal>
      <ModalExecucaoManual
        @mostrarModalExecucaoManual="mostrarModalExecucaoManual"
        @fecharModalExecucaoManual="fecharModalExecucaoManual"
        :campanha="itemSelecionado"
      />

      <div class="d-flex flex-column justify-content-between h-100 w-100">
        <b-table
          id="tabelaCampanhas"
          small
          primary-key="modelo_campanha_id"
          :current-page="currentPage"
          :fields="fields"
          :items="campanhasFiltradas"
          :per-page="selectQuant.value"
          @row-clicked="detalhes"
          responsive="sm"
          :filter-included-fields="licenciadaSelecionadaFiltrada"
          hover
          sort-by="automacao_ativa"
          :sort-desc="true"
          ref="refteste"
        >
          <template #cell(mdc_nome)="data">
            <div class="mt-3">
              {{ data.value }}
            </div>
          </template>

          <template #cell(modelo_campanha_id)="data">
            <div class="mt-3">
              {{ data.value || "-" }}
            </div>
          </template>

          <template #cell(cron_texto)="data">
            <div class="mt-3">
              {{ data.value || "-" }}
            </div>
          </template>

          <template #cell(mdc_data_ultima_execucao)="data">
            <div class="h-100 w-100 mt-3" @click="ordenarProximaExecucao">
              {{ obterDataFormatada(data.value) }}
            </div>
          </template>

          <template #cell(proxima_execucao)="data">
            <div class="h-100 w-100 mt-3" @click="ordenarProximaExecucao">
              {{ obterDataFormatada(data.value.proxima_execucao) }}
            </div>
          </template>

          <template #cell(mdc_status_ultima_execucao)="data">
            <div
              class="h-100 w-100 mt-3"
              @click.prevent="abrirRelatorio(data.item)"
            >
              <i
                v-b-tooltip.hover.html="{
                  placement: 'top',
                  html: true,
                  title: `<strong>${
                    data.value == 'FS'
                      ? 'CONCLUÍDO'
                      : data.value == 'FE'
                      ? 'CONCLUÍDO COM ERRO'
                      : data.value == 'PC'
                      ? 'PROCESSANDO'
                      : data.value == 'AO'
                      ? 'AGENTE OFFLINE'
                      : data.value == 'TI'
                      ? 'TEMPO EXCEDIDO'
                      : 'ERRO NA CAMPANHA'
                  }</strong>
                  <br>Enviados: <strong>${
                    data.item.mdc_ultimo_qtd_enviado
                  }</strong><br>Enviados com erro:
                  <strong>${data.item.mdc_ultimo_qtd_enviado_erro}</strong>`,
                  variant:
                    data.value == 'FS'
                      ? 'success'
                      : data.value == 'FE'
                      ? 'warning'
                      : data.value == 'PC'
                      ? 'primary'
                      : data.value == 'AO'
                      ? 'info'
                      : 'danger'
                }"
                :class="`menu-icon icon-md flaticon2-infographic ${
                  ['FS'].includes(data.value)
                    ? 'success'
                    : ['PC'].includes(data.value)
                    ? 'primary'
                    : ['EC', 'AO', 'TI'].includes(data.value)
                    ? 'danger'
                    : data.value == 'FE'
                    ? 'warning'
                    : 'error'
                }`"
                style="cursor: pointer"
              ></i>
            </div>
          </template>

          <template #cell(canais_label)="data">
            <div class="w-50 h-100">
              <b-badge
                v-for="canal in data.value"
                :key="canal"
                style="margin-top: 1px; margin-left: 1px"
                pill
                variant="primary"
              >
                {{ canal.toLowerCase() }}
              </b-badge>
            </div>
          </template>

          <template #head(sli_titulo)="data">
            <div class="d-flex align-items-center justify-content-between">
              <span class="">{{ data.label }}</span>
              <MultiSelect
                v-if="licenciadasDropdown.length > 1"
                :class="`mr-2 filtro-elementos ${
                  !licenciadaSelecionadaFiltrada.length
                    ? 'filtro-elementos'
                    : 'filtro-elementos-selecionado'
                }`"
                @change="filtrarElementos"
                v-model="licenciadaSelecionadaFiltrada"
                :options="licenciadasDropdown"
                placeholder=""
              />
            </div>
          </template>
          <template #cell(sli_titulo)="data">
            <div class="mt-3">
              {{ data.value }}
            </div>
          </template>
          <template #cell(automacao_ativa)="data">
            <div class="camp-center mt-4 ml-4">
              <status-components :ativo="data.value" />
            </div>
          </template>

          <template #cell()="row">
            <i>{{ row.value }}</i>
            <b-dropdown
              no-caret
              variant="outline-secondary"
              id="dropdown-dropleft"
              dropleft
              class="border-0"
            >
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>
              <b-dropdown-item href="#" @click="detalhes(row.item)"
                >Detalhes</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="editarCampanha(row.item)"
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                >Editar</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="mostrarModalExecucaoManual(row.item)"
                v-if="isAdmin || hasPermission('executar-campanha')"
                >Execução Manual</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="duplicarCampanha(row.item)"
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                >Duplicar</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.CANAIS && verificarDashPresente(row)"
                href="#"
                @click="abrirPainelAmostra(row.item)"
                >Painel de Amostra</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="showDeletarCampanhaModal(row.item)"
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                >Remover</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex w-100">
          <b-row class="w-100">
            <b-col md="4"
              ><b-form-select
                v-model="selectQuant.value"
                :options="selectQuant.options"
              ></b-form-select
            ></b-col>
            <b-col md="8"
              ><b-pagination
                v-model="currentPage"
                ref="pagination"
                :total-rows="campanhasFiltradas.length"
                :per-page="selectQuant.value"
                aria-controls="tabelaCampanhas"
                align="fill"
                @change="salvarConfiguracoes"
              ></b-pagination
            ></b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatusComponents from "./components/StatusComponents.vue";
import ModalDetalhesCampanha from "./components/ModalDetalhesCampanha.vue";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../api.links";
import moment from "moment";
import "moment/locale/pt-br";
import Toast from "primevue/toast";
import Swal from "sweetalert2";
import MultiSelect from "primevue/multiselect";
import ModalExecucaoManual from "./components/ModalExecucaoManual.vue";
import store from "@/core/services/store/store";

export default {
  components: {
    StatusComponents,
    ModalDetalhesCampanha,
    Toast,
    MultiSelect,
    ModalExecucaoManual
  },
  props: {
    campanhas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      campanhaSelecionada: {},
      campanhaDeletarNome: "",
      carregando: true,
      fields: [
        {
          key: "automacao_ativa",
          label: "Auto",
          sortable: true,
          class: "cursor-pointer",
          thStyle: { width: "60px !important" }
        },
        {
          key: "modelo_campanha_id",
          label: "ID",
          sortable: true,
          class: "cursor-pointer align-middle",
          thStyle: { width: "80px !important" }
        },
        {
          key: "mdc_nome",
          label: "Nome da campanha",
          sortable: true,
          class: "cursor-pointer",
          thStyle: { width: "300px !important" }
        },
        {
          key: "cron_texto",
          label: "Frequência",
          class: "cursor-pointer",
          thStyle: { width: "250px !important" }
        },
        {
          key: "proxima_execucao",
          label: "Próxima Execução",
          class: "cursor-pointer",
          thStyle: { width: "200px !important" }
        },
        {
          key: "mdc_data_ultima_execucao",
          label: "Última Execução",
          class: "cursor-pointer",
          thStyle: { width: "200px !important" }
        },
        {
          key: "mdc_status_ultima_execucao",
          label: "",
          class: "cursor-pointer",
          thStyle: { width: "100px !important" }
        },
        {
          key: "canais_label",
          label: "Canais",
          class: "cursor-pointer",
          thStyle: { width: "150px !important" }
        },
        {
          key: "sli_titulo",
          label: "Licenciada",
          class: "cursor-pointer",
          thStyle: { width: "100px !important" }
        },
        {
          key: "opcoes",
          label: "Opções",
          class: "text-center",
          thStyle: { width: "60px !important" }
        }
      ],
      bancos: [],
      itens: [],
      data: {},
      currentPage: 1,
      perPage: 7,
      itemSelecionado: {},
      transProps: {
        name: "flip-list"
      },
      licenciadaSelecionadaFiltrada: [],
      licenciadasDropdown: [],
      selectQuant: {
        value: "7",
        options: [
          {
            text: "Exibir 7 registros por página",
            value: "7"
          },
          {
            text: "Exibir 15 registros por página",
            value: "15"
          },
          {
            text: "Exibir 30 registros por página",
            value: "30"
          },
          {
            text: "Exibir 50 registros por página",
            value: "50"
          },
          {
            text: "Exibir todos",
            value: "999"
          }
        ]
      }
    };
  },
  watch: {
    "selectQuant.value": {
      handler(newValue) {
        this.salvarConfiguracoes(this.currentPage);
      },
      immediate: false
    },
    currentPage: {
      handler(newValue) {
        this.salvarConfiguracoes(newValue);
        const total_possible_pages = Math.ceil(
          this.campanhasFiltradas.length / this.selectQuant.value
        );
        if (newValue > total_possible_pages) {
          this.currentPage = total_possible_pages;
        }
      },
      immediate: false
    }
  },
  methods: {
    abrirRelatorio(execucao) {
      const objPeriodo = this.obterPeriodo(
        execucao.mdc_data_ultima_execucao,
        execucao.mdc_data_ultima_execucao
      );

      this.$router.push({
        name: "relatorio-detalhado",
        params: {
          periodo: objPeriodo,
          modelo: [
            {
              text: execucao.mdc_nome,
              value: execucao.modelo_campanha_id
            }
          ]
        }
      });
    },
    verificarDashPresente(row) {
      if (row.item.CANAIS[0]) {
        if (row.item.CANAIS[0].canal_id == 5) {
          return true;
        }
      }
      return false;
    },
    mostrarModalExecucaoManual(item) {
      this.itemSelecionado = item;
      this.$modal.show("modal-execucao-manual");
    },
    mostrarModalPreview(item) {
      this.itemSelecionado = item;
      this.$modal.show("modal-preview");
    },
    fecharModalExecucaoManual() {
      this.itemSelecionado = null;
      this.$modal.hide("modal-execucao-manual");
    },
    fecharModalPreview() {
      this.itemSelecionado = null;
      this.$modal.hide("modal-preview");
    },
    duplicarCampanha(campanha) {
      Swal.fire({
        title: "Duplicar campanha",
        text:
          'Deseja realmente duplicar a campanha "' + campanha.mdc_nome + '" ?',
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, duplicar!",
        cancelButtonText: "Não, cancelar!",
        icon: "warning"
      }).then((result) => {
        if (result.value) {
          ApiService.get(
            API_LINKS.campanha + "/" + campanha.modelo_campanha_id
          ).then(async (res) => {
            const campanhaDaRequisicao = res.data.campanha;

            const cronsInativos = campanhaDaRequisicao.CRONS.map((v) => {
              return {
                mdc_ativo: false,
                mdc_cron: v.mdc_cron,
                mdc_cron_txt: v.mdc_cron_txt,
                mdc_data_fim: v.mdc_data_fim,
                mdc_data_inicio: v.mdc_data_inicio,
                mdc_status: v.mdc_status,
                modelo_campanha_id: v.modelo_campanha_id
              };
            });

            const callback = campanhaDaRequisicao.CALLBACK
              ? campanhaDaRequisicao.CALLBACK.callback_id
              : null;

            const chatbot_callback = await ApiService.get(
              API_LINKS.chatbot_callback
            );
            const callbacks_de_chatbot_da_campanha =
              chatbot_callback.data.callbacks.filter(
                (v) =>
                  v.modelo_campanha_id ==
                  campanhaDaRequisicao.modelo_campanha_id
              );
            const objetoParaEnviarNoCadastro = {
              ...campanhaDaRequisicao,
              mdc_nome: campanhaDaRequisicao.mdc_nome + " (CÓPIA)",
              mdc_status: "A",
              canais: campanhaDaRequisicao.CANAIS.map((v) => {
                v.modelo_canal_id = null;
                return v;
              }),
              filtros: campanhaDaRequisicao.FILTROS,
              funcoes_pos_envio: campanhaDaRequisicao.FUNCOES_POS_ENVIO,
              canais_excluir: [],
              crons: cronsInativos,
              callback_id: callback
            };

            ApiService.post(API_LINKS.campanha, {
              campanha: objetoParaEnviarNoCadastro
            }).then((res) => {
              const modelo_campanha_id = res.data.campanha.modelo_campanha_id;
              callbacks_de_chatbot_da_campanha.forEach((callback) => {
                const payload = {
                  callback: {
                    chatbot_fluxo_id: callback.chatbot_fluxo_id,
                    modelo_campanha_id: modelo_campanha_id
                  }
                };
                ApiService.post(API_LINKS.chatbot_callback, payload);
              });
              Swal.fire({
                title: "Sucesso!",
                text: "Campanha duplicada com sucesso!",
                type: "success",
                icon: "success"
              });
              this.$emit("pegarCampanhas");
            });
          });
        }
      });
    },
    abrirPainelAmostra(campanha) {
      localStorage.sistema_licenciada_id = campanha.sistema_licenciada_id;
      let routeData = this.$router.resolve({
        name: "painel-amostra",
        params: {
          campanha: campanha,
          modelo_campanha_id: campanha.CANAIS[0].modelo_campanha_id
        }
      });
      window.open(routeData.href, "_blank");
    },
    editarCampanha(campanha) {
      this.$emit("editarCampanha", campanha);
    },
    showDeletarCampanhaModal(campanha) {
      this.campanhaSelecionada = campanha;
      Swal.fire({
        title:
          'Você deseja remover a campanha "' +
          this.campanhaSelecionada.mdc_nome +
          '"?',
        text: "Não será possivel reverter essa ação! Você pode desativar a campanha sem precisar removê-la.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero remover!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Campanha Removida",
            `Campanha "${this.campanhaSelecionada.mdc_nome}" removida.`,
            "success"
          );
          ApiService.delete(
            API_LINKS.campanha +
              "/" +
              this.campanhaSelecionada.modelo_campanha_id
          ).then((_) => {
            this.$emit("pegarCampanhas");
          });
        }
      });
    },
    detalhes(item) {
      this.itemSelecionado = item;
      this.$bvModal.show("modal-detalhes-campanha");
    },
    filtrarElementos() {
      this.currentPage = 1;
      if (!this.licenciadaSelecionadaFiltrada.length) {
        this.itens = this.itensCopia;
        this.salvarConfiguracoes(this.currentPage);
        this.adicionaAtributosItens();
        return;
      }
      this.itens = this.itensCopia.filter((item) => {
        for (let i = 0; i < this.licenciadaSelecionadaFiltrada.length; i++) {
          if (item.sli_titulo == this.licenciadaSelecionadaFiltrada[i])
            return true;
        }
        return false;
      });
      this.salvarConfiguracoes(this.currentPage);
      this.adicionaAtributosItens();
    },
    obterDataFormatada(data) {
      return data
        ? moment(data, "YYYY-MM-DD hh:mm:ss")
            .locale("pt-br")
            .format("DD[/]MM[/]YY [-] HH:mm")
        : "-";
    },
    adicionaAtributosItens() {},
    ordenarProximaExecucao() {
      this.itens = this.itens.sort(function (a, b) {
        if (a.proxima_execucao < b.proxima_execucao) return -1;
        if (a.proxima_execucao > b.proxima_execucao) return 1;
        return 0;
      });
    },
    pegarLicenciadas() {
      let licenciada = [];
      this.itens.forEach((item) => {
        licenciada.push(item.sli_titulo);
      });

      this.licenciadasDropdown = licenciada.filter(
        (este, i) => licenciada.indexOf(este) === i
      );
    },
    showOverlay(on) {
      this.$emit("showOverlay", on);
      this.carregando = on;
    },
    salvarConfiguracoes(currentPage) {
      store.state.paginacao = {
        perPage: this.selectQuant.value,
        currentPage: currentPage,
        licenciadaSelecionadaFiltrada: this.licenciadaSelecionadaFiltrada
      };
    }
  },
  mounted() {
    const totalCampanhas = this.campanhasFiltradas.length;
    const quantidadePorPagina = this.selectQuant.value;
    const paginaAtual = this.currentPage;
    const totalPaginas = Math.ceil(totalCampanhas / quantidadePorPagina);
    if (paginaAtual > totalPaginas) {
      this.currentPage = 1;
    }

    if (store.state.paginacao) {
      const new_page = store.state.paginacao.currentPage;
      this.currentPage = store.state.paginacao.currentPage ?? this.currentPage;
      this.selectQuant.value =
        store.state.paginacao.perPage ?? this.selectQuant.value;
      this.licenciadaSelecionadaFiltrada =
        store.state.paginacao.licenciadaSelecionadaFiltrada ?? [];
      setTimeout(() => {
        this.$refs.pagination.$data.currentPage = new_page;
      }, 3000);
    }
  },
  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),
    campanhasFiltradas() {
      return this.campanhas;
    }
  }
};
</script>

<style scoped>
/deep/ .filtro-elementos {
  border: none;
  box-shadow: none !important;
  margin-top: 4px;
}

/deep/ .filtro-elementos .p-multiselect-label-container {
  display: none;
}

/deep/
  .filtro-elementos
  .p-multiselect-trigger
  .p-multiselect-trigger-icon:before {
  content: "\e94c" !important;
  color: black;
}

/deep/
  .filtro-elementos-selecionado
  .p-multiselect-trigger
  .p-multiselect-trigger-icon:before {
  content: "\e94c" !important;
  color: #2979c8;
}

.modal-deletar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.camp-center {
  display: flex;
  width: 50%;
  height: 100%;
}

.botao-opcoes {
  border: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.largura-coluna {
  background-color: #3eef33;
  max-width: 300px;
}

.p-multiselect-trigger-icon.pi.pi-chevron-down::before {
  content: none;
}

table#tabelaCampanhas .flip-list-move {
  transition: transform 0.5s;
}
.menu-icon.success {
  color: #18c5bd;
}

.menu-icon.primary {
  color: #3599fe;
}

.menu-icon.danger {
  color: #f44336;
}

.menu-icon.warning {
  color: #ffa800;
}
</style>
