<template>
  <div :class="{ 'status-on': ativo, 'status-off': !ativo }"></div>
</template>

<script>
export default {
  props: ["ativo"]
};
</script>

<style scoped>
.status-on {
  background-color: rgb(97, 191, 3);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
.status-off {
  background-color: rgb(214, 21, 21);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
</style>
>
